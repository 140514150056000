import React, { useState, useEffect } from 'react';
import './App.css';
import './styles/style.css';
import './styles/bootstrap.css';
import './fonts/css/fontawesome-all.min.css';
import '../node_modules/react-resizable/css/styles.css'
import '../node_modules/react-grid-layout/css/styles.css'
import RGL, { WidthProvider } from "react-grid-layout";

const GridLayout = WidthProvider(RGL);
function App() {

  const id = window.location.href.split('/')[window.location.href.split('/').length - 1];

  const [loading, setLoading] = useState(true);

  const [appData, setAppData] = useState({} as any);
  const [pages, setPages] = useState([] as any[]);
  const [pageData, setPageData] = useState({} as any);
  useEffect(() => {
    const requestOptions = {
      method: 'GET'
    };
    fetch(`${process.env.REACT_APP_API_URL}public/${id}`, requestOptions)
      .then(response => response.json())
      .then(data => { setAppData(data); loadPages(id); });
  }, []);

  const loadPages = async (id: string) => {
    const requestOptions = {
      method: 'GET'
    };
    fetch(`${process.env.REACT_APP_API_URL}public/pages/${id}`, requestOptions)
      .then(response => response.json())
      .then(data => { setPages(data); setPageData(data[0]); setLoading(false); });
  }

  const renderBlock = (block: any) => {
    if (!block) return <></>
    if (block.type === "basic-card") {
        return <>
            <div className="card card-style rounded-m no-user-select">
                <div className="content mb-0">
                    <h1 className="text-center mb-0">{block.content[0]}</h1>
                    <p className="text-center color-highlight font-11 mt-n1">{block.content[1]}</p>
                    <p className="boxed-text-xl mt-n3">
                        {block.content[2]}
                    </p>
                </div>
            </div>
        </>
    }
    if (block.type === "image-card") {
        return <>
            <div className="card card-style rounded-m shadow-l mx-3 no-user-select">
                <div className="card-bottom text-center mb-0">
                    <h1 className="color-white font-700 mb-n1">{block.content[1]}</h1>
                    <p className="color-white opacity-80 mb-4">{block.content[2]}</p>
                </div>
                <div className="card-overlay bg-gradient"></div>
                <img className="img-fluid" src={block.content[0]} />
            </div>
        </>
    }
    if (block.type === "event") {
        return <>
            <div className="card card-style  no-user-select rounded-m">
                <div data-card-height="200" className="card shadow-l mb-0" style={{ height: '150px', backgroundImage: `url(${block.content[0]})` }}>
                    <div className="card-bottom ms-3">
                        <p className="color-white font-10 opacity-80 mb-n1"><i className="far fa-calendar"></i> {block.content[1]} <i className="ms-3 far fa-clock"></i> {block.content[2]}</p>
                        <p className="color-white font-10 opacity-80 mb-2"><i className="fa fa-map-marker-alt"></i> {block.content[3]}</p>
                    </div>
                    <div className="card-overlay bg-gradient opacity-90 rounded-0"></div>
                </div>
                <div className="content mb-0">
                    <div className="float-start">
                        <h1 className="mb-n1">{block.content[4]}</h1>
                        <p className="font-10 mb-2 pb-1">{block.content[5]}</p>
                    </div>
                </div>
            </div></>
    }
    if (block.type === "meeting") {
        return <>
            <div className="card card-style no-user-select rounded-m">
                <div className="content mb-4">
                    <h3>{block.content[0]}</h3>
                    <p>
                        {block.content[1]}
                    </p>
                    <div className="divider mb-3"></div>
                    <div className="row mb-0">
                        <div className="col-6">
                            <p className="text-center mb-0 pb-3"><i className="fa fa-calendar-alt me-2 color-yellow-dark"></i>{block.content[2]}</p>
                        </div>
                        <div className="col-6">
                            <p className="text-end mb-0 pb-3"><i className="fa fa-clock me-2 color-green-dark"></i>{block.content[3]}</p>
                        </div>
                    </div>
                </div>
            </div></>
    }
}

  return (
    <>
      <div className="app-container">
        <div style={{ height: '100%' }} className="theme-light" data-highlight="highlight-red" data-gradient="body-default">

          {loading && <div id="preloader"><div className="spinner-border color-highlight" role="status"></div></div>}

          <div style={{ height: '100%' }} id="page">
            <div style={{ height: '100%' }} className="page-content">
              <div className="phone">
                {pageData.layout && <GridLayout
                  className="layout"
                  layout={pageData.layout}
                  cols={4}
                  isResizable={false}
                  isDraggable={false}
                  isDroppable={false}
                  rowHeight={10}
                >
                  {pageData.layout.map((x: any) => <div key={x.i} onClick={() => { console.log(pageData.elements.find((y: any) => `block${y.id}` === x.i)); if (pageData.elements.find((y: any) => `block${y.id}` === x.i).link && pageData.elements.find((y: any) => `block${y.id}` === x.i).link.length > 0) setPageData(pages.find((y: any) => y._id == pageData.elements.find((y: any) => `block${y.id}` === x.i).link)) }}>
                    {renderBlock(pageData.elements.find((y: any) => `block${y.id}` === x.i))}
                  </div>)}
                </GridLayout>}
              </div>



            </div>


          </div>

        </div>
      </div>
    </>
  );
}

export default App;
